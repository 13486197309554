import React from "react"
import "./style.css"
import { Col, Container, Row } from "react-bootstrap"

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="footer-row">
          <Col md={3} sm={5} className="box">
            <div className="logo">
              <h1>Ganjmo</h1>
            </div>
            <p>
              Welcome to Ganjmo E-Comer, your ultimate destination for
              hassle-free online shopping. At Ganjmo E-Comer, we bring you a
              seamless shopping experience right at your fingertips. Discover a
              vast selection of products ranging from electronics to fashion,
              home essentials to gadgets, all curated to meet your needs.
              Address: Baraikhali, Morrelganj,Bagerhat
            </p>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>About Us</h2>
            <h5>Address: Baraikhali, Morrelganj,Bagerhat</h5>
            <h5>TIN # 448240472927</h5>
            <ul>
              <li>Careers</li>
              <li>Our Stores</li>
              <li>Our Cares</li>
              <li>Terms & Conditions</li>
              <li>Privacy Policy</li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>Customer Care</h2>
            <ul>
              <li>Help Center </li>
              <li>How to Buy </li>
              <li>Track Your Order </li>
              <li>Corporate & Bulk Purchasing </li>
              <li>Returns & Refunds </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer
